import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import FileUpload from './FileUpload';
import { fetchOpenAIResponse } from '../services/openaiService';
import { handleCommand } from '../services/commandService'; // Import nowego serwisu
import ReactMarkdown from 'react-markdown';
import '../css/ChatBot.css';
import { FaCircleArrowRight } from "react-icons/fa6";

const ChatBot: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [prompt, setPrompt] = useState<string>('');
  const [messages, setMessages] = useState<{ role: string, content: string, isChart?: boolean }[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showCommands, setShowCommands] = useState<boolean>(false);
  const [filteredCommands, setFilteredCommands] = useState<string[]>([]);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null); // Referencja do inputa
  const pyodideRef = useRef<any>(null);
  const navigate = useNavigate();

  const commands = ['#addUser: ', '#showUsers: ', '#changePrompt: ', '#showPrompt: '];

  useEffect(() => {
    const loadPyodideAndPackages = async () => {
      try {
        pyodideRef.current = await (window as any).loadPyodide();
        await pyodideRef.current.loadPackage('micropip');
        await pyodideRef.current.runPythonAsync(`
          import micropip
          await micropip.install('matplotlib')
        `);
        console.log('Pyodide and matplotlib loaded');
      } catch (error) {
        console.error('Error loading Pyodide or matplotlib:', error);
      }
    };

    loadPyodideAndPackages();
  }, []);

  const handleFileUpload = (file: File) => {
    setFile(file);
  };

  const handlePromptSubmit = async (prompt: string) => {
    const newMessages = [...messages, { role: 'user', content: prompt }];
    setMessages(newMessages);
    setPrompt(''); // Clear the input field
    setIsLoading(true);
    setShowCommands(false); // Hide commands list on submit
  
    try {
      if (prompt.startsWith('#')) {
        // If the prompt is a command, handle it accordingly
        const commandResponse = await handleCommand(prompt);
        setMessages((prevMessages) => [...prevMessages, { role: 'ai', content: JSON.stringify(commandResponse) }]);
      } else {
        // Otherwise, fetch the response from OpenAI
        const openAIResponse = await fetchOpenAIResponse(prompt, file || undefined);
        console.log('Response from OpenAI:', openAIResponse);
        setMessages((prevMessages) => [...prevMessages, { role: 'ai', content: openAIResponse }]);
  
        // Extract Python code from OpenAI response
        const pythonCode = openAIResponse.replace(/```python|```/g, '').trim();
        await generateChart(pythonCode);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error:', error);
      let errorMessage = 'Error processing request';
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      setMessages((prevMessages) => [...prevMessages, { role: 'ai', content: errorMessage }]);
      setIsLoading(false);
    }
  };
  

  const handlePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPrompt(value);

    if (value.includes('#')) {
      const query = value.split('#').pop() || '';
      const filtered = commands.filter((command) =>
        command.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredCommands(filtered);
      setShowCommands(true);
    } else {
      setShowCommands(false);
    }
  };

  const handleCommandSelect = (command: string) => {
    setPrompt(command);
    setShowCommands(false); // Hide commands list on command select
    if (inputRef.current) {
      inputRef.current.focus(); // Set focus on input
    }
  };

  const generateChart = async (pythonCode: string) => {
    try {
      if (!pyodideRef.current) {
        console.error('Pyodide is not loaded');
        return;
      }

      console.log('Running Python code:', pythonCode);

      const sanitizedPythonCode = pythonCode.replace(/plt\.show\(\)/g, '').trim();

      const fullPythonCode = `
import os
import matplotlib.pyplot as plt
import io
import base64

# Upewnij się, że katalog istnieje
os.makedirs('/mnt/data', exist_ok=True)

${sanitizedPythonCode}

buf = io.BytesIO();
plt.savefig(buf, format='png');
buf.seek(0);
img_data = buf.read();

# Zapisz bufor obrazu do pliku (do debugowania)
with open('/mnt/data/debug_chart.png', 'wb') as f:
    f.write(img_data);

img_str = "data:image/png;base64," + base64.b64encode(img_data).decode('utf-8');
img_str
`;

      const imageDataUrl = await pyodideRef.current.runPythonAsync(fullPythonCode);
      console.log('Generated image data URL:', imageDataUrl);

      if (!imageDataUrl || imageDataUrl === 'data:image/png;base64,') {
        throw new Error('Empty image data URL');
      }

      setMessages((prevMessages) => [...prevMessages, { role: 'ai', content: imageDataUrl, isChart: true }]);
    } catch (error) {
      console.error('Error generating chart:', error);
      // setMessages((prevMessages) => [...prevMessages, { role: 'ai', content: 'Error generating chart' }]);
    }
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <div className="chat-container">
      <div className="chat-messages">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.role === 'user' ? 'user-message' : 'ai-message'}`}>
            {message.isChart ? (
              <img src={message.content} alt="Generated chart" style={{ maxWidth: '100%' }} />
            ) : (
              <ReactMarkdown>{message.content}</ReactMarkdown>
            )}
          </div>
        ))}
        {isLoading && (
          <div className="loading">
            <img src="/loading.gif" alt="Loading..." />
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className="chat-input">
        {showCommands && (
          <div className="commands-list">
            {filteredCommands.map((command, index) => (
              <div key={index} className="command-item" onClick={() => handleCommandSelect(command)}>
                {command}
              </div>
            ))}
          </div>
        )}
        <form onSubmit={(e) => { e.preventDefault(); handlePromptSubmit(prompt); }}>
          <input
            type="text"
            value={prompt}
            onChange={handlePromptChange}
            placeholder="Zadaj pytanie i naciśnij enter"
            required
            ref={inputRef} // Attach the ref to the input
          />
          {/* <button type="submit">Wyślij</button> */}
        </form>
        <div className='chat-buttons'>
          <FileUpload onFileUpload={handleFileUpload} />
            <button className="logout-button" onClick={handleLogout}>
              Wyloguj się
            </button>
        </div>
      </div>
    </div>
  );
};

export default ChatBot;

// src/components/ProtectedRoute.tsx
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import axios from 'axios';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || '';

const ProtectedRoute: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      axios.get(`${apiBaseUrl}/auth/status`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then(response => {
        setIsAuthenticated(true);
      }).catch(error => {
        console.error('Error fetching auth status:', error);
        setIsAuthenticated(false);
      });
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return <>{children || <Outlet />}</>;
};

export default ProtectedRoute;

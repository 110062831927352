// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginByCode from './components/LoginByCode';
import ChatBot from './components/ChatBot';
import ProtectedRoute from './components/ProtectedRoute';
import AuthSuccess from './components/AuthSuccess';
import EnterCode from './components/EnterCode';

const App: React.FC = () => {
  const token = localStorage.getItem('token');

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginByCode />} />
        <Route path="/auth/success" element={<AuthSuccess />} />
        <Route path="/" element={token ? <Navigate to="/chatbot" replace /> : <Navigate to="/login" replace />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/chatbot" element={<ChatBot />} />
          <Route path="/enter-code" element={<EnterCode />} />
        </Route>
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </Router>
  );
};

export default App;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../css/login-by-code.css';

const LoginByCode: React.FC = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [step, setStep] = useState<'email' | 'code'>('email');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    const body = document.body;
    body.classList.add('auth-step');

    // Clean up on unmount
    return () => {
      body.classList.remove('auth-step');
    };
  }, [step]);

  const handleEmailSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      await axios.post(`${apiBaseUrl}/auth/send-code`, { email });
      setStep('code');
    } catch (error: any) {
      setErrorMessage('Error sending code. Please try again.');
      console.error('Error sending code:', error);
    }
  };

  const handleCodeSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${apiBaseUrl}/auth/verify-code`, { email, code });
      localStorage.setItem('token', response.data.access_token);
      console.log(response);

      document.body.classList.remove('auth-step');

      // Dodaj klasę zmieniającą tło
      document.body.classList.add('change-background');

      // Usuń klasę po 3 sekundach (lub innym czasie)
      setTimeout(() => {
        document.body.classList.remove('change-background');
        navigate('/chatbot');
      }, 2000); // 3000 milisekund = 3 sekundy

    } catch (error: any) {
      setErrorMessage('Invalid code. Please try again.');
      console.error('Error verifying code:', error);
    }
  };

  return (
    <div className="login-container">
      {step === 'email' ? (
        <form onSubmit={handleEmailSubmit}>
          <input 
            type="email" 
            value={email}
            placeholder="Podaj adres e-mail i naciśnij enter"
            onChange={e => setEmail(e.target.value)} 
            required 
          />
        </form>
      ) : (
        <form onSubmit={handleCodeSubmit}>
          <input 
            type="text" 
            value={code}
            placeholder="Wprowadź kod dostępu i naciśnij enter"
            onChange={e => setCode(e.target.value)} 
            required 
          />
        </form>
      )}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default LoginByCode;

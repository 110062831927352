import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/enter-code.css';


const EnterCode: React.FC = () => {
  const [appCode, setAppCode] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || '';

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const token = localStorage.getItem('token');

    if (token) {
      try {
        const response = await axios.post(`${apiBaseUrl}/auth/enter-code`, { appCode }, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (response.status === 201) {
          console.log('go to chatbot')
          navigate('/chatbot');
        } else {
          setErrorMessage('Error: App code not accepted.');
        }
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          setErrorMessage(error.response.data.message);
        } else {
          console.error('Error submitting app code:', error);
          setErrorMessage('Error submitting app code.');
        }
      }
    } else {
      navigate('/login');
    }
  };

  return (
    <div className="code-container">
      <div className="code-header">
        <h1>Wprowadź kod aplikacji</h1>
      </div>
      <form onSubmit={handleSubmit} className="code-form">
          <input 
            type="text" 
            value={appCode} 
            onChange={e => {
              setAppCode(e.target.value);
              setErrorMessage(null);
            }} 
            required 
          />
        <button type="submit">OK</button>
      </form>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default EnterCode;

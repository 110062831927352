import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const AuthSuccess: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    console.log('AuthSuccess component mounted');
    if (token) {
      console.log('Token found in AuthSuccess:', token);
      localStorage.setItem('token', token);

      axios.get(`${apiBaseUrl}/auth/status`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then(response => {
        console.log('Response from auth/status in AuthSuccess:', response);
        if (response.data.hasAppCode) {
          navigate('/chatbot');
        } else {
          navigate('/enter-code');
        }
      }).catch(error => {
        console.error('Error checking user status in AuthSuccess:', error);
        navigate('/login');
      });
    } else {
      console.log('No token found in AuthSuccess');
      navigate('/login');
    }
  }, [token, navigate, apiBaseUrl]);

  return <div>Loading...</div>;
};

export default AuthSuccess;

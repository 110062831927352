import axios from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

const token = localStorage.getItem('token');
const headers = {
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${token}`
};

// Uniwersalna funkcja do obsługi komend
export const handleCommand = async (commandString: string) => {
  try {
    const response = await axios.post(
      `${API_URL}/commands/handle-command`,
      { commandString },
      { headers }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Typujemy error jako AxiosError
      const errorMessage = error.response?.data?.message || 'An error occurred';
      throw new Error(errorMessage);
    } else {
      // Inny błąd
      throw new Error('An error occurred');
    }
  }
};

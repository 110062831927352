import axios from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';
const baseUri = `${API_URL}/open-ai`;

export const fetchOpenAIResponse = async (prompt: string, file?: File): Promise<string> => {
  let fileContent = '';

  if (file) {
    const reader = new FileReader();
    reader.onload = () => {
      fileContent = reader.result as string;
    };
    reader.readAsText(file);
    await new Promise((resolve) => reader.onloadend = resolve);
  }

  const token = localStorage.getItem('token');

  if (!token) {
    throw new Error('No token found');
  }

  const response = await axios.post(baseUri, { prompt, fileContent }, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return response.data;
};

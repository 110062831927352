import React, { useState } from 'react';
import '../css/ChatBot.css';

interface FileUploadProps {
  onFileUpload: (file: File) => void;
}

const FileUpload: React.FC<FileUploadProps> = ({ onFileUpload }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);
      onFileUpload(file);
    }
  };

  return (
    <div className="file-upload-container">
      <label htmlFor="file-upload" className="file-upload-button">
        Wybierz plik
      </label>
      <input
        id="file-upload"
        type="file"
        className="file-upload-input"
        onChange={handleFileChange}
      />
      {selectedFile && <p>Wybrano plik: {selectedFile.name}</p>}
    </div>
  );
};

export default FileUpload;
